import { get, post, patch, destroy } from '../http';
import { UpdateUser, CreateUserPayload } from '@/core/types/user';
import { sprintf } from '@/core/utils';

const Routes = {
  users: '/api/rest/private/users',
  userTeams: '/api/rest/private/users/%s/teams',
  permissionables: '/api/rest/private/permissionables',
  databases: '/api/rest/private/databases',
  login: '/api/rest/private/auth/login',
  generateToken: '/api/rest/private/auth/generate_token',
  changePassword: '/api/rest/private/users/%s/update_password',
  updateUser: '/api/rest/private/users/%s',
  createUser: '/api/rest/private/users',
  setUserRole: '/api/rest/private/users/role/%s',
  unlockUser: '/api/rest/private/users/%s/unlock',
  authenticateUser: '/api/rest/private/users/authenticate',
  userKey: '/api/rest/private/integrations/userkey?partner_app_id=%s',
  deleteUserKey: '/api/rest/private/integrations/users/%s',
  rotateUserKey: '/api/rest/private/integrations/users/%s?partner_app_id=%s'
};

const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;

const headers = { 'content-type': 'application/json' };

export const getDatabaseUsers = async () => {
  const url = BASE_URL + Routes.users;
  return get({ url, headers });
};

export const getUserTeams = async (userId: number) => {
  const url = BASE_URL + sprintf(Routes.userTeams, userId);
  return get({ url, headers });
};

export const getPermissionables = async () => {
  const url = BASE_URL + Routes.permissionables;
  return get({ url, headers });
};

export const getAvailableDatabases = async () => {
  const url = BASE_URL + Routes.databases;
  return get({ url, headers });
};

export const login = async (token: string | string[]) => {
  const url = BASE_URL + Routes.login;
  return post({ url, body: JSON.stringify({ token }), headers });
};

export const generateToken = async () => {
  const url = BASE_URL + Routes.generateToken;
  return get({ url, headers });
};

export const changePassword = async (id: string, password: string) => {
  const url = BASE_URL + sprintf(Routes.changePassword, id);
  return patch({ url, body: JSON.stringify({ password }), headers });
};

export const updateUser = async (id: string, data: UpdateUser) => {
  const url = BASE_URL + sprintf(Routes.updateUser, id);
  return patch({ url, body: JSON.stringify(data), headers });
};

export const createUser = async (data: CreateUserPayload) => {
  const url = BASE_URL + Routes.createUser;
  return post({ url, body: JSON.stringify(data), headers });
};

export const setUserRole = async (id: string, role: { admin: boolean }) => {
  const url = BASE_URL + sprintf(Routes.setUserRole, id);
  return patch({ url, body: JSON.stringify(role), headers });
};

export const unlockUser = async (id: string) => {
  const url = BASE_URL + sprintf(Routes.unlockUser, id);
  return post({ url, headers });
};

export const authenticateUser = async (username: string, password: string) => {
  const url = BASE_URL + Routes.authenticateUser;
  return post({ url, body: JSON.stringify({ username, password }), headers });
};

export const getUserKey = async (partnerAppId: string) => {
  const url = BASE_URL + sprintf(Routes.userKey, partnerAppId);
  return get({ url, headers });
};

export const createUserKey = async (partnerAppId: string) => {
  const url = BASE_URL + sprintf(Routes.userKey, partnerAppId);
  return post({ url, headers });
};

export const deleteUserKey = async (partnerUserId: string) => {
  const url = BASE_URL + sprintf(Routes.deleteUserKey, partnerUserId);
  return destroy({ url, headers });
};

export const rotateUserKey = async (partnerUserId: string, partnerAppId) => {
  const url = BASE_URL + sprintf(Routes.rotateUserKey, partnerUserId, partnerAppId);
  return post({ url, headers });
};
