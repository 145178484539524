import { ReactNode } from 'react';
import { ArrowCircleUpIcon } from '@heroicons/react/solid';

import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';

export default class FileCreateHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser) {
    super(history, user);
    this.action = 'uploaded';
  }

  detailText(): ReactNode {
    if (this.history.attributes?.migrated) {
      return this.history.attributes?.migrated.description;
    } else {
      return (
        <div data-testid="create-file-history">
          The {this.history.entity_type}{' '}
          <span className="font-semibold break-all">{this.history.attributes?.next.name}</span> was{' '}
          {this.action}.
        </div>
      );
    }
  }

  detailIcon(): ReactNode {
    return <ArrowCircleUpIcon className="h-5 fill-support-success" />;
  }
}
