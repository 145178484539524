import React, { ReactNode, useEffect } from 'react';
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid';

import { TextButton } from '../buttons';

interface Props {
  title: string;
  children: ReactNode;
  onClose(): void;
  onCancel?: () => void;
}

export default function SuccessNotification({ onClose, onCancel, title, children }: Props) {
  useEffect(() => {
    const timeout = setTimeout(() => onClose(), 8000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <div className="pt-4 px-3.5 pb-3.5 mx-1 w-[22rem] sm:w-96" data-testid="success-notification">
        <div className="flex gap-2.5 items-center">
          <CheckCircleIcon className="hidden sm:inline h-5 text-support-success" />
          <div className="flex-1 header-03">{title}</div>
          <TextButton data-testid="notification-close" onClick={onClose}>
            <span className="sr-only">Close</span>
            <XIcon className="h-5" aria-hidden="true" />
          </TextButton>
        </div>
        <div className="flex mt-1 ml-4 pl-3.5">
          <div className="flex-1 body-01 break-all">{children}</div>
        </div>
        {onCancel && (
          <div className="flex ml-4 pl-3.5 mt-1.5">
            <div className="shrink-0 flex">
              <TextButton data-testid="notification-undo" onClick={onCancel}>
                Undo
              </TextButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
