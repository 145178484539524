import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

import { EntityRow, EntityType, SelectedEntity } from '../types';

interface ProviderProps {
  children: ReactNode;
}

interface ContextType {
  isEntityPanelOpen: boolean;
  isUploadPanelOpen: boolean;
  isUploadPanelMinimized: boolean;
  selectedEntity: SelectedEntity | undefined;
  currentFolder: EntityRow;
  closeUploadPanel(): void;
  closeEntityPanel(): void;
  setIsEntityPanelOpen: Dispatch<SetStateAction<boolean>>;
  setIsUploadPanelOpen: Dispatch<SetStateAction<boolean>>;
  setIsUploadPanelMinimized: Dispatch<SetStateAction<boolean>>;
  setSelectedEntity: Dispatch<SetStateAction<SelectedEntity | undefined>>;
  setCurrentFolder: Dispatch<SetStateAction<EntityRow>>;
}

const UiContext = createContext<ContextType | undefined>(undefined);

function UiProvider({ children }: ProviderProps) {
  const defaultCurrentFolderState: EntityRow = {
    id: '',
    name: 'Root',
    created_at: '',
    favorite: false,
    type: EntityType.FOLDER,
    updated_at: ''
  };

  const [isEntityPanelOpen, setIsEntityPanelOpen] = useState(false);
  const [isUploadPanelOpen, setIsUploadPanelOpen] = useState(false);
  const [isUploadPanelMinimized, setIsUploadPanelMinimized] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState<SelectedEntity | undefined>(undefined);
  const [currentFolder, setCurrentFolder] = useState<EntityRow>(defaultCurrentFolderState);

  const closeUploadPanel = () => {
    setIsUploadPanelOpen(false);
    setIsUploadPanelMinimized(false);
  };

  const closeEntityPanel = () => {
    setIsEntityPanelOpen(false);
    setSelectedEntity(undefined);
  };

  const value = {
    isEntityPanelOpen,
    isUploadPanelOpen,
    isUploadPanelMinimized,
    selectedEntity,
    currentFolder,
    setIsEntityPanelOpen,
    setIsUploadPanelOpen,
    setIsUploadPanelMinimized,
    setSelectedEntity,
    setCurrentFolder,
    closeUploadPanel,
    closeEntityPanel
  };

  return <UiContext.Provider value={value}>{children}</UiContext.Provider>;
}

function useUiContext() {
  const context = useContext(UiContext);
  if (context === undefined) {
    throw new Error('useUiContext must be used within a UiProvider');
  }
  return context;
}

export { UiProvider, useUiContext };
