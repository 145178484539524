import { get, post, destroy as destroyRequest } from '../http';
import { Contact } from '../types';
import { sprintf } from '@/core/utils';

const BASE_URL = process.env.NEXT_PUBLIC_REST_API_URL as string;

export const Routes = {
  create: '/folder_contacts',
  byFolderId: '/folders/%s/folder_contacts',
  destroy: '/folder_contacts/%s?contact_id=%s'
};

interface CreateInput {
  contact: Contact;
  folder_id: string;
}

interface DestroyInput {
  id: string;
  contact_id: string;
}

export const create = async ({ ...props }: CreateInput) => {
  const url = BASE_URL + Routes.create;
  return post({ url, body: JSON.stringify(props) });
};

export const byFolderId = async ({ folder_id }: { folder_id: string }) => {
  const url = BASE_URL + sprintf(Routes.byFolderId, folder_id);
  return get({ url });
};

export const destroy = async ({ id }: DestroyInput) => {
  const url = BASE_URL + sprintf(Routes.destroy, id);
  return destroyRequest({ url });
};
