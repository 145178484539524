import { useCallback, useEffect } from 'react';
import { differenceInMilliseconds, differenceInMinutes } from 'date-fns';
import { useRouter } from 'next/router';

import { userStorage } from '../storage';
import { useNotificationContext, useUppyContext } from '../context';
import { NotificationActionType } from '../reducers/action-types';
import SessionTimeoutNotification from './notifications/session-timeout';
import SuccessNotification from './notifications/success';
import { CurrentUser } from '@imaging/types';

interface Props {
  currentUser: CurrentUser;
}

let WARNING_MINUTES = 1000 * 60 * 28;
let TIMEOUT_MINUTES = 30;

export default function SessionTimeout({ currentUser }: Props) {
  const { dispatch } = useNotificationContext();
  const { uppy } = useUppyContext();
  const { push } = useRouter();

  const events = ['click', 'load'];

  let warningInactiveInterval: ReturnType<typeof setTimeout>;
  let startTimerInterval: ReturnType<typeof setTimeout>;
  let mounted = true;

  if (currentUser.isLfgSso) {
    WARNING_MINUTES = 1000 * 60 * 478;
    TIMEOUT_MINUTES = 480;
  }

  const onExtend = () => {
    dispatch({
      type: NotificationActionType.OpenPanel,
      payload: { component: extendNotification() }
    });
    resetTimer();
  };

  const extendNotification = () => {
    return (
      <SuccessNotification
        onClose={() => dispatch({ type: NotificationActionType.ClosePanel })}
        title="Session timeout"
      >
        Your session has been extended for {currentUser.isLfgSso ? '8 hours' : '30 minutes'}.
      </SuccessNotification>
    );
  };

  const timeoutNotification = () => {
    return (
      <SessionTimeoutNotification
        onClose={() => dispatch({ type: NotificationActionType.ClosePanel })}
        onExtend={onExtend}
        title="Session timeout"
      />
    );
  };

  const handleLogout = () => {
    dispatch({ type: NotificationActionType.ClosePanel });
    clearInterval(warningInactiveInterval);
    push('/logout');
  };

  const startTimer = () => {
    startTimerInterval = setTimeout(async () => {
      if (await isUserActive(Date.now())) {
        dispatch({ type: NotificationActionType.ClosePanel });
        resetTimer();
      } else {
        handleInactive(Date.now());
      }
    }, WARNING_MINUTES);
  };

  const isUserUploading = () => {
    return Object.keys(uppy.getState().currentUploads).length > 0;
  };

  const isUserActive = async (currentDate: number) => {
    if (isUserUploading()) {
      await userStorage.set({ lastTimeStamp: Date.now() });
    }

    const user = await userStorage.get();
    return user && differenceInMilliseconds(currentDate, user.lastTimeStamp) < WARNING_MINUTES;
  };

  const handleInactive = (warningStartTime: number) => {
    clearTimeout(startTimerInterval);
    if (mounted) {
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: timeoutNotification() }
      });
    }

    warningInactiveInterval = setInterval(async () => {
      const currentDate = Date.now();

      if (await isUserActive(currentDate)) {
        dispatch({ type: NotificationActionType.ClosePanel });
        return resetTimer();
      }

      if (!(differenceInMinutes(currentDate, warningStartTime) < 2)) {
        handleLogout();
      }
    }, 1000);
  };

  const resetTimer = useCallback(async () => {
    const user = await userStorage.get();
    const lastTimeStamp = Date.now();

    if (user && differenceInMinutes(lastTimeStamp, user.lastTimeStamp) > TIMEOUT_MINUTES) {
      handleLogout();
    }

    clearTimeout(startTimerInterval);
    clearInterval(warningInactiveInterval);
    await userStorage.set({ lastTimeStamp });
    startTimer();
  }, []);

  useEffect(() => {
    events.forEach((event) => window.addEventListener(event, resetTimer));
    resetTimer();

    return () => {
      mounted = false;
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, []);

  return null;
}
