import { ReactNode } from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';

import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';

export default class FileUpdateHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser) {
    super(history, user);
    this.action = 'updated';
  }

  detailText(): ReactNode {
    return (
      <div data-testid="history-detail-text">
        The file{' '}
        <span className="font-semibold break-all">{this.history.attributes?.prev.name}</span> was{' '}
        {this.action}.
      </div>
    );
  }

  detailIcon(): ReactNode {
    return <InformationCircleIcon className="h-5 fill-support-info" />;
  }
}
