import { ReactNode } from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';

import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';

export default class FileRenameHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser) {
    super(history, user);
    this.action = 'renamed';
  }

  detailText(): ReactNode {
    if (this.history.attributes?.migrated) {
      return this.history.attributes?.migrated.description;
    } else {
      return (
        <div data-testid="history-detail-text">
          The file{' '}
          <span className="font-semibold break-all">{this.history.attributes?.prev.name}</span> was
          renamed to{' '}
          <span className="font-semibold break-all">{this.history.attributes?.next.name}</span>.
        </div>
      );
    }
  }

  detailIcon(): ReactNode {
    return <InformationCircleIcon className="h-5 fill-support-info" />;
  }
}
