import { XCircleIcon, XIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';

import { TextButton } from '../buttons';
import { humanizeMilliseconds } from '@/core/utils';

interface Props {
  title: string;
  onClose(): void;
  onExtend(): void;
}

const initialState = 120 * 1000;

export default function SessionTimeoutNotification({ onClose, onExtend, title }: Props) {
  const [timeRemaining, setTimeRemaining] = useState(initialState);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining((prev) => prev - 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="pt-4 px-3.5 pb-3.5 mx-1 w-[22rem] sm:w-96">
        <div className="flex gap-2.5 items-center">
          <XCircleIcon className="hidden sm:inline h-5 text-support-error" />
          <div className="flex-1 header-03">{title}</div>
          <TextButton onClick={onClose}>
            <span className="sr-only">Close</span>
            <XIcon className="h-5" aria-hidden="true" />
          </TextButton>
        </div>
        <div className="flex mt-1 ml-4 pl-3.5">
          <div className="flex-1 body-01">
            {`Extend your session? You have ${humanizeMilliseconds(timeRemaining)} left`}
          </div>
        </div>
        <div className="flex ml-4 pl-3.5 mt-1.5">
          <div className="shrink-0 flex">
            <TextButton onClick={onExtend}>Extend</TextButton>
          </div>
        </div>
      </div>
    </>
  );
}
