import { ReactNode } from 'react';
import { Transition } from '@headlessui/react';

interface Props {
  children: ReactNode;
  open: boolean;
}

export default function ActionPanel({ open, children }: Props) {
  return (
    <Transition
      show={open}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className="bg-ui-background border border-ui-03 shadow-lg rounded z-10 absolute bottom-8 left-6"
        data-testid="action-panel"
      >
        {children}
      </div>
    </Transition>
  );
}
