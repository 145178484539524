interface GetInput {
  url: string;
  headers?: HeadersInit;
}

interface PostInput {
  url: string;
  body?: BodyInit;
  headers?: HeadersInit;
}

type PatchInput = PostInput;
type DestroyInput = PostInput;

const defaultHeaders = {
  'content-type': 'application/json'
};

export const post = async ({ url, body = '', headers = {} }: PostInput) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      ...defaultHeaders,
      ...headers
    },
    credentials: 'include',
    body
  });
};

export const patch = async ({ url, body, headers = {} }: PatchInput) => {
  return fetch(url, {
    method: 'PATCH',
    headers: {
      ...defaultHeaders,
      ...headers
    },
    credentials: 'include',
    body
  });
};

export const destroy = async ({ url, headers = {} }: DestroyInput) => {
  return fetch(url, {
    method: 'DELETE',
    headers: {
      ...defaultHeaders,
      ...headers
    },
    credentials: 'include'
  });
};

export const get = async ({ url, headers = {} }: GetInput) => {
  return fetch(url, {
    method: 'GET',
    headers: {
      ...defaultHeaders,
      ...headers
    },
    credentials: 'include'
  });
};
