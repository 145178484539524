import React, { ComponentProps, forwardRef } from 'react';

import { classNames, sizeClasses } from '@/core/utils';
import { ButtonSizes } from '@/core/types';

interface Props {
  size: ButtonSizes;
}

const TertiaryButton = forwardRef<HTMLButtonElement, Props & ComponentProps<'button'>>(
  (props, ref) => {
    const { disabled, size, className, children, ...rest } = props;
    const disabledClasses = classNames(
      size.includes('Icon') ? '' : 'disabled:border-disabled-01 disabled:bg-disabled-01',
      'disabled:text-03 disabled:cursor-not-allowed'
    );
    const focusClasses =
      'focus-visible:border-focus-01 focus-visible:ring-focus-ring focus-visible:ring-4 focus:outline-none';

    return (
      <button
        type="button"
        ref={ref}
        disabled={disabled}
        {...rest}
        className={classNames(
          disabled ? '' : 'hover:bg-hover-03',
          'flex items-center gap-2 button rounded-full',
          sizeClasses[size],
          disabledClasses,
          focusClasses,
          className
        )}
      >
        {children}
      </button>
    );
  }
);

export default TertiaryButton;
