import React, { ComponentProps } from 'react';

import { classNames } from '@/core/utils';

export default function TextButton({ ...props }: ComponentProps<'button'>) {
  return (
    <button
      {...props}
      type="button"
      className={classNames(
        props.disabled ? 'text-03 cursor-not-allowed' : 'text-02 hover:underline',
        `button hover:text-01 focus:text-01`,
        props.className,
        'focus-visible:border-focus-01 focus-visible:ring-focus-ring focus-visible:ring-4 focus:outline-none select-none'
      )}
    >
      {props.children}
    </button>
  );
}
