import { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';

import { ApplyIndexAction, applyIndexReducer, ApplyIndexState } from '../reducers/apply-index';
import { ApplyIndexActionType } from '../reducers/action-types';

interface ProviderProps {
  children: ReactNode;
  initialState?: ApplyIndexState;
}

interface ContextType {
  state: ApplyIndexState;
  dispatch: Dispatch<ApplyIndexAction>;
  clearState(): void;
}

const ApplyIndexFormContext = createContext<ContextType | undefined>(undefined);

function ApplyIndexFormProvider({ children, initialState }: ProviderProps) {
  const [state, dispatch] = useReducer(applyIndexReducer, initialState || {});

  const clearState = () => {
    dispatch({ type: ApplyIndexActionType.ClearState, payload: { state: {} } });
  };

  const value = {
    state,
    dispatch,
    clearState
  };

  return <ApplyIndexFormContext.Provider value={value}>{children}</ApplyIndexFormContext.Provider>;
}

function useApplyIndexFormContext() {
  const context = useContext(ApplyIndexFormContext);
  if (context === undefined) {
    throw new Error('useApplyIndexFormContext must be used within a ApplyIndexFormProvider');
  }
  return context;
}

export { ApplyIndexFormProvider, useApplyIndexFormContext };
