import { Color } from '@/core/types';

export const Colors = {
  Default: { lightHexCode: '#20B2E9', darkHexCode: '#1E9AC9', name: 'Default', isDefault: true },
  Grey: { lightHexCode: '#D3D3D3', darkHexCode: '#B6B6B6', name: 'Grey' },
  DarkRed: { lightHexCode: '#B70000', darkHexCode: '#9F0303', name: 'DarkRed' },
  LightRed: { lightHexCode: '#FF5252', darkHexCode: '#DC4949', name: 'LightRed' },
  DarkOrange: { lightHexCode: '#F0750F', darkHexCode: '#CF6610', name: 'DarkOrange' },
  LightOrange: { lightHexCode: '#F9D186', darkHexCode: '#D7B575', name: 'LightOrange' },
  DarkYellow: { lightHexCode: '#D9B100', darkHexCode: '#E8D127', name: 'DarkYellow' },
  LightYellow: { lightHexCode: '#FFE146', darkHexCode: '#DCC23F', name: 'LightYellow' },
  DarkGreen: { lightHexCode: '#64B01C', darkHexCode: '#58991B', name: 'DarkGreen' },
  LightGreen: { lightHexCode: '#AAD984', darkHexCode: '#B6DC93', name: 'LightGreen' },
  DarkBlue: { lightHexCode: '#2C9AFF', darkHexCode: '#2886DC', name: 'DarkBlue' },
  LightBlue: { lightHexCode: '#C4DDFF', darkHexCode: '#AABFDC', name: 'LightBlue' },
  DarkPurple: { lightHexCode: '#AB96FB', darkHexCode: '#9483D8', name: 'DarkPurple' },
  LightPurple: { lightHexCode: '#B5ADD9', darkHexCode: '#9993AD', name: 'LightPurple' },
  DarkPink: { lightHexCode: '#F453AD', darkHexCode: '#D24A96', name: 'DarkPink' },
  LightPink: { lightHexCode: '#FFBFD3', darkHexCode: '#DCA5B6', name: 'LightPink' }
};

export const renderColorCode = (name?: string): Color => {
  if (name) {
    return Colors[name];
  }

  return Colors.Default;
};
