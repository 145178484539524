import { useEffect } from 'react';
import { useUppyContext } from '../context';

const BeforeUnload = () => {
  const { uppy } = useUppyContext();

  const userUploading = () => {
    return Object.keys(uppy.getState().currentUploads).length > 0;
  };

  const confirmNavigation = (event: BeforeUnloadEvent) => {
    if (userUploading()) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  useEffect(() => {
    window.onbeforeunload = confirmNavigation;

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return null;
};

export default BeforeUnload;
