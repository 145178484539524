import { ApplyIndexActionType } from './action-types';
import { omit } from 'lodash';

export interface ApplyIndexState {
  [key: string]: { value: string; indexFieldId: string };
}

export interface ApplyIndexAction {
  type: ApplyIndexActionType;
  payload: Record<string, any>;
}

export function applyIndexReducer(state: ApplyIndexState, action: ApplyIndexAction) {
  switch (action.type) {
    case ApplyIndexActionType.UpdateValue:
      return {
        ...state,
        [action.payload.id]: {
          value: action.payload.value,
          indexFieldId: action.payload.id
        }
      };
    case ApplyIndexActionType.ClearState:
      return { ...action.payload.state };
    case ApplyIndexActionType.RemoveKey:
      return omit(state, action.payload.id);
    default:
      return state;
  }
}
