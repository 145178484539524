import { ReactNode } from 'react';
import { PlusCircleIcon } from '@heroicons/react/solid';

import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';
import { Permissionable } from '@imaging/types';

export default class FolderPermissionCreateManyHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser, permissionables: Permissionable[]) {
    super(history, user, permissionables);
    this.action = 'shared';
  }

  displayPermissionables(): ReactNode {
    const names = this.history.attributes?.next.permissions.map((permission) => {
      return this.permissionables.reduce((acc: string[], data: Permissionable) => {
        if (
          data.permissionable_id === permission.permissionable_id &&
          data.permissionable_type === permission.permissionable_type
        ) {
          acc.push(data.name);
        }
        return acc;
      }, []);
    });

    return <span className="font-semibold break-all">{names.join(', ')}</span>;
  }

  detailText(): ReactNode {
    return (
      <div data-testid="history-detail-text">
        The folder{' '}
        <span className="font-semibold break-all">{this.history.attributes?.next.folder_name}</span>{' '}
        was {this.action} with {this.displayPermissionables()}.
      </div>
    );
  }

  displayText(): ReactNode {
    return (
      <>
        <b>{this.userName}</b> {this.action} this folder
      </>
    );
  }

  detailIcon(): ReactNode {
    return <PlusCircleIcon className="h-5 fill-support-success" />;
  }
}
