import { ReactNode } from 'react';
import { XCircleIcon } from '@heroicons/react/solid';
import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';
import { Permissionable } from '@imaging/types';

export default class FolderPermissionDeleteHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser, permissionables: Permissionable[]) {
    super(history, user, permissionables);
    this.action = 'removed';
  }

  displayPermissionables(): ReactNode {
    const permission = this.history.attributes?.next;
    const permissionable = this.permissionables.find(
      (p) =>
        p.permissionable_id === permission.permissionable_id &&
        p.permissionable_type === permission.permissionable_type
    );

    return <span className="font-semibold break-all">{permissionable?.name}</span>;
  }

  displayText(): ReactNode {
    return (
      <>
        <b>{this.userName}</b> {this.action} a permission
      </>
    );
  }

  detailText(): ReactNode {
    return (
      <div data-testid="history-detail-text">
        Permission for {this.displayPermissionables()} was {this.action} from the folder{' '}
        <span className="font-semibold break-all">{this.history.attributes?.next.folder_name}</span>
      </div>
    );
  }

  detailIcon(): ReactNode {
    return <XCircleIcon className="h-5 fill-support-error" />;
  }
}
