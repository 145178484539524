import { ComponentProps } from 'react';

export function FilterSolid({ ...props }: ComponentProps<'svg'>) {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 3C13.2652 3 13.5196 2.89465 13.7071 2.70711C13.8946 2.51957 14 2.26522 14 2C14 1.73479 13.8946 1.48043 13.7071 1.2929C13.5196 1.10536 13.2652 1 13 1L11.732 1C11.5565 0.695989 11.304 0.443536 11 0.268016C10.6959 0.0924953 10.3511 9.13932e-05 10 9.13779e-05C9.64894 9.13625e-05 9.30407 0.0924952 9.00004 0.268016C8.69602 0.443536 8.44354 0.695989 8.268 1L1 1C0.734783 1 0.480429 1.10536 0.292892 1.2929C0.105356 1.48043 -7.5826e-08 1.73479 -8.74189e-08 2C-9.90119e-08 2.26522 0.105356 2.51957 0.292892 2.70711C0.480429 2.89465 0.734783 3 1 3L8.268 3C8.44354 3.30402 8.69602 3.55647 9.00004 3.73199C9.30407 3.90751 9.64894 3.99991 10 3.99991C10.3511 3.99991 10.6959 3.90751 11 3.73199C11.304 3.55647 11.5565 3.30402 11.732 3L13 3ZM14 7.99992C14 8.26513 13.8946 8.51949 13.7071 8.70703C13.5196 8.89456 13.2652 8.99992 13 8.99992L5.732 8.99992C5.55646 9.30393 5.30398 9.55639 4.99996 9.73191C4.69593 9.90743 4.35106 9.99983 4 9.99983C3.64894 9.99983 3.30407 9.90743 3.00004 9.73191C2.69602 9.55639 2.44354 9.30393 2.268 8.99992L1 8.99992C0.734783 8.99992 0.480428 8.89456 0.292892 8.70702C0.105356 8.51949 -3.61277e-07 8.26513 -3.49684e-07 7.99992C-3.38091e-07 7.7347 0.105356 7.48035 0.292892 7.29281C0.480428 7.10527 0.734783 6.99992 1 6.99992L2.268 6.99992C2.44354 6.6959 2.69602 6.44345 3.00004 6.26793C3.30407 6.09241 3.64894 6 4 6C4.35106 6 4.69593 6.09241 4.99996 6.26793C5.30398 6.44345 5.55646 6.6959 5.732 6.99992L13 6.99992C13.2652 6.99992 13.5196 7.10527 13.7071 7.29281C13.8946 7.48035 14 7.7347 14 7.99992Z"
        fill="#475363"
      />
    </svg>
  );
}
