import { ReactNode } from 'react';
import { ArrowCircleDownIcon } from '@heroicons/react/solid';

import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';

export default class FileDownloadHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser) {
    super(history, user);
    this.action = this.buildDownloadAction();
  }

  buildDownloadAction(): string {
    const attributes = this.history.attributes;
    if (!attributes?.download) return 'downloaded';
    if (attributes.download.preview) return 'previewed';
    if (attributes.download.print) return 'printed';
    return 'downloaded';
  }

  detailIcon(): ReactNode {
    return <ArrowCircleDownIcon className="h-5 fill-support-success" />;
  }
}
