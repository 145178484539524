import { ComponentProps } from 'react';

export function ImagingIcon({ ...props }: ComponentProps<'svg'>) {
  return (
    <svg {...props} viewBox={'0 0 52 52'}>
      <path d="M26,2A24,24,0,1,0,50,26,24,24,0,0,0,26,2Zm0,46A22,22,0,1,1,48,26,22,22,0,0,1,26,48Z" />
      <path d="M29.18,12.57a9.53,9.53,0,0,0-3.49,2.1L14.45,25.9a1,1,0,0,0,1.42,1.42L27.1,16.08c.44-.43,4.4-4.11,8.51,0s.43,8.08,0,8.51L22.38,37.82a3.51,3.51,0,0,1-5.2,0,3.54,3.54,0,0,1,0-5.2L28.75,21.05a1.34,1.34,0,1,1,1.9,1.89l-8.76,8.75a1,1,0,0,0,1.42,1.42l8.75-8.76a3.34,3.34,0,0,0-4.72-4.72L15.76,31.21a5.48,5.48,0,0,0,0,8A5.46,5.46,0,0,0,19.66,41a6.13,6.13,0,0,0,4.13-1.77L37,26c2-2,4.49-6.86,0-11.34A7.26,7.26,0,0,0,29.18,12.57Z" />
    </svg>
  );
}
