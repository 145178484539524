import { get, patch, post } from '../http';
import { EntityType } from '../types';
import { sprintf } from '../utils';

const BASE_REST_URL = process.env.NEXT_PUBLIC_REST_API_URL as string;

export const Routes = {
  get: '/applied_indexes?entity_id=%s',
  create: '/applied_indexes',
  update: '/applied_indexes/%s',
  archive: '/applied_indexes/%s/archive',
  restore: '/applied_indexes/%s/restore',
  destroy: '/applied_indexes/%s',
  updateMany: '/applied_indexes/update_many'
};

interface CreateInput {
  indexes: {
    entity_id: string;
    entity_type: EntityType;
    index_field_id: string;
    value: any;
  }[];
}

interface UpdateInput {
  id: string;
  value: string;
}

interface UpdateManyInput {
  oldValue: string;
  value: string;
  indexId: string;
}

export const fetch = ({ entityId }: { entityId: string }) => {
  const url = BASE_REST_URL + sprintf(Routes.get, entityId);
  return get({ url });
};

export const create = ({ indexes }: CreateInput) => {
  const url = BASE_REST_URL + Routes.create;
  return post({ url, body: JSON.stringify(indexes) });
};

export const update = ({ id, value }: UpdateInput) => {
  const url = BASE_REST_URL + sprintf(Routes.update, id);
  return patch({ url, body: JSON.stringify({ value }) });
};

export const updateMany = ({ indexId, value, oldValue }: UpdateManyInput) => {
  const url = BASE_REST_URL + Routes.updateMany;
  return post({ url, body: JSON.stringify({ indexId, value, oldValue }) });
};

export const archive = ({ id }: { id: string }) => {
  const url = BASE_REST_URL + sprintf(Routes.archive, id);
  return post({ url });
};

export const restore = ({ id }: { id: string }) => {
  const url = BASE_REST_URL + sprintf(Routes.restore, id);
  return post({ url });
};
