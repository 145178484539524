import { differenceInHours, format, formatDistance, differenceInMinutes, sub, add } from 'date-fns';

export const formatDate = (date: Date) => {
  const currentDate = new Date();
  if (differenceInMinutes(currentDate, date) < 1) {
    return 'Just Now';
  } else if (differenceInHours(currentDate, date) >= 3) {
    return date.toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  } else {
    return formatDistance(date, currentDate, { addSuffix: true });
  }
};

export const stringFormat = (date: Date, formatString?: string) => {
  // Default format is Feb 10, 2021 1:33pm returned date format
  return format(date, formatString || 'LLL dd, yyyy h:mm aaa');
};

export const formatWithoutTime = (date: Date) => {
  // ignores localization
  // Feb 10, 2021 returned date format
  const dateParts = date.toUTCString().split(' ');
  return `${dateParts[2]} ${dateParts[1]}, ${dateParts[3]}`;
};

export const formatFilterDate = (date: Date, days: number) => {
  date.setHours(0, 0, 0, 0);

  if (days > 0) {
    return add(date, { days: days });
  } else {
    return sub(date, { days: Math.abs(days) });
  }
};

export const humanizeMilliseconds = (seconds: number) => {
  return formatDistance(0, seconds, { includeSeconds: true });
};
