import { UppyFile } from '@uppy/core';

import { FileGateway } from '../gateways';

export const recordCreation = async (file: UppyFile, url: string) => {
  const urlParams = new URLSearchParams(url.split('?')[1]);
  const databaseId = urlParams.get('x-amz-meta-databaseid');
  const { id } = JSON.parse(
    Buffer.from(urlParams.get('x-amz-meta-file') || '', 'base64').toString('utf-8')
  );

  const payload = {
    id,
    folder_id: String(file.meta.folderId),
    name: file.name,
    extension: String(file.meta.extension),
    size: file.size,
    content_type: file.type || 'application/octet-stream',
    created_by: Number(databaseId),
    database_id: Number(databaseId)
  };

  await FileGateway.record(payload);
};
