import { Folder, RawFolder } from './folder';
import { File, RawFile } from './file';
import { FolderContact } from './folder-contact';
import { DatabaseUser } from '@/core/types/user';
import { FolderColor } from '@/core/types/folder_color';

export interface EntityRow {
  id: string;
  name: string;
  parent_id?: string;
  folder_id?: string;
  created_at: string;
  favorite: boolean;
  color?: FolderColor;
  size?: number;
  type: EntityType.FILE | EntityType.FOLDER;
  extension?: string;
  contacts?: FolderContact[];
  updated_at: string;
  archived?: boolean;
  archived_by?: number;
  user?: DatabaseUser;
}

export interface SelectedEntity {
  id: string;
  type: EntityType.FILE | EntityType.FOLDER;
}

export interface FetchedEntities {
  folders: Folder[];
  files: File[];
}

export interface RawFetchedEntities {
  folders: RawFolder[];
  files: RawFile[];
}

export interface SearchedEntities {
  folders: Folder[];
  files: File[];
  folder_contacts: FolderContact[];
}

export interface RawSearchedEntities {
  folders: RawFolder[];
  files: RawFile[];
  folder_contacts: FolderContact[];
}

export interface PagedSearchedEntities extends SearchedEntities {
  nextPage: number;
  prevPage: number;
}

export enum EntityType {
  FILE = 'file',
  FOLDER = 'folder',
  BREADCRUMB = 'breadcrumb',
  APPLIED_INDEX = 'applied_index',
  FOLDER_CONTACT = 'folder_contact',
  CONTACT = 'contact',
  INDEX_FIELD = 'index_field'
}
