import { get, patch, post } from '../http';
import { sprintf } from '@/core/utils';

const BASE_URL = process.env.NEXT_PUBLIC_REST_API_URL || process.env.REST_API_URL;

export const Routes = {
  create: '/files',
  update: '/files/%s',
  archive: '/files/%s/archive',
  restore: '/files/%s/restore',
  uploaded: '/files/%s/uploaded',
  stats: '/files/stats',
  statsByType: '/files/stats/by_type',
  fetch: '/files/%s',
  preview: '/files/%s/preview',
  download: '/files/%s/download',
  print: '/files/%s/print',
  history: '/files/%s/history',
  record: '/files/record',
  favorite: '/files/%s/favorite'
};

interface CreateInput {
  folder_id: string;
  name: string;
  extension: string;
  content_type: string;
}

interface UpdateInput {
  id: string;
  name?: string;
  folder_id?: string;
}

export interface CreateFileRecordInput extends CreateInput {
  id: string;
  size: number;
  created_by: number;
  database_id: number;
}

export const create = async ({ ...props }: CreateInput) => {
  const url = BASE_URL + Routes.create;
  return post({ url, body: JSON.stringify(props) });
};

export const record = async (file: CreateFileRecordInput) => {
  const url = BASE_URL + Routes.record;
  return post({ url, body: JSON.stringify(file) });
};

export const update = async ({ id, ...props }: UpdateInput) => {
  const url = BASE_URL + sprintf(Routes.update, id);
  return patch({ url, body: JSON.stringify(props) });
};

export const archive = async ({ id }: { id: string }) => {
  const url = BASE_URL + sprintf(Routes.archive, id);
  return post({ url });
};

export const restore = async ({ id }: { id: string }) => {
  const url = BASE_URL + sprintf(Routes.restore, id);
  return post({ url });
};

export const fetch = async ({ id }: { id: string }) => {
  const url = BASE_URL + sprintf(Routes.fetch, id);
  return get({ url });
};

export const fetchStats = () => {
  const url = BASE_URL + Routes.stats;
  return get({ url });
};

export const fetchStatsByType = () => {
  const url = BASE_URL + Routes.statsByType;
  return get({ url });
};

export const fetchHistory = async ({ id }: { id: string }) => {
  const url = BASE_URL + sprintf(Routes.history, id);
  return get({ url });
};

export const preview = async ({ id }: { id: string }) => {
  const url = BASE_URL + sprintf(Routes.preview, id);
  return get({ url });
};

export const print = async ({ id }: { id: string }) => {
  const url = BASE_URL + sprintf(Routes.print, id);
  return get({ url });
};

export const download = async ({ id, headers }: { id: string; headers: HeadersInit }) => {
  const url = BASE_URL + sprintf(Routes.download, id);
  return get({ url, headers });
};

export const favorite = async ({ id, enabled }: { id: string; enabled: boolean }) => {
  const url = BASE_URL + sprintf(Routes.favorite, id);
  return patch({
    url,
    body: JSON.stringify({ enabled })
  });
};
