import { sortBy } from 'lodash';

import { IndexFormState } from '@/core/types';

function buildDropdownOptions(initialState: IndexFormState) {
  return sortBy(initialState.values, 'value', (value: any) => value.position).map((value: any) => ({
    name: value.name
  }));
}

export function buildInitialState(initialState?: IndexFormState) {
  if (initialState) {
    return {
      ...initialState,
      values: buildDropdownOptions(initialState)
    };
  } else {
    return {
      name: '',
      type: 'text_field',
      description: '',
      values: []
    };
  }
}
