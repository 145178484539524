import { post, get } from '../http';

export const enable = async (databaseId: number) => {
  return post({
    url: process.env.NEXT_PUBLIC_API_BASE_URL + '/api/rest/private/integrations/enable',
    body: JSON.stringify({ databaseId })
  });
};

export const disable = async (databaseId: number) => {
  return post({
    url: process.env.NEXT_PUBLIC_API_BASE_URL + '/api/rest/private/integrations/disable',
    body: JSON.stringify({ databaseId })
  });
};

export const fetch = async () => {
  return get({
    url: process.env.NEXT_PUBLIC_API_BASE_URL + '/api/rest/private/integrations'
  });
};
