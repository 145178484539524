import { datadogRum } from '@datadog/browser-rum';

interface ResponseOptions {
  returnType: 'blob' | 'json';
}

export async function handleResponse(res: Promise<Response>, options?: ResponseOptions) {
  const response = await res;

  const getData = async () => {
    if (options?.returnType === 'blob') {
      return response.blob();
    } else {
      return response.json();
    }
  };

  const data = await getData();

  if (response.ok) {
    return data;
  } else {
    datadogRum.addError(data);
    throw new Error(data.message);
  }
}
