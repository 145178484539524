import localforage from 'localforage';

import { Storage } from '../types';

interface StorageUser {
  lastTimeStamp: number;
}

export default class UserStorage implements Storage {
  private key = 'img_user';

  async get() {
    return localforage.getItem<StorageUser>(this.key);
  }

  async set(userObject: StorageUser) {
    await localforage.setItem(this.key, userObject);
  }

  async clear() {
    await localforage.removeItem(this.key);
  }
}
