export function sprintf(template: string, ...values: any[]) {
  if (template.includes('%s')) {
    return template.replace(/%s/g, () => {
      return values.shift();
    });
  } else {
    throw new Error('sprintf must be used with a %s replaceable string.');
  }
}

// splits string value of SplitMe into Split Me.
export function splitProperCaseString(value: string) {
  return value.replace(/([a-z])([A-Z])/g, '$1 $2');
}
