interface Props {
  className: string;
}

export function SpinnerIcon({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="20"
      height="20"
      className={className}
      data-testid="spinner"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4C8.81332 4 7.65328 4.35189 6.66658 5.01118C5.67989 5.67047 4.91085 6.60754 4.45673 7.7039C4.0026 8.80026 3.88378 10.0067 4.11529 11.1705C4.3468 12.3344 4.91825 13.4035 5.75736 14.2426C6.59648 15.0818 7.66558 15.6532 8.82946 15.8847C9.99335 16.1162 11.1997 15.9974 12.2961 15.5433C13.3925 15.0892 14.3295 14.3201 14.9888 13.3334C15.6481 12.3467 16 11.1867 16 10C16 9.44772 16.4477 9 17 9C17.5523 9 18 9.44772 18 10C18 11.5823 17.5308 13.129 16.6518 14.4446C15.7727 15.7602 14.5233 16.7855 13.0615 17.391C11.5997 17.9965 9.99113 18.155 8.43928 17.8463C6.88743 17.5376 5.46197 16.7757 4.34315 15.6569C3.22433 14.538 2.4624 13.1126 2.15372 11.5607C1.84504 10.0089 2.00347 8.40034 2.60897 6.93853C3.21447 5.47672 4.23985 4.22729 5.55544 3.34824C6.87104 2.46919 8.41775 2 10 2C10.5523 2 11 2.44772 11 3C11 3.55228 10.5523 4 10 4Z"
      />
    </svg>
  );
}
