import { get, patch, post } from '../http';
import { sprintf } from '@/core/utils';

const BASE_URL = process.env.NEXT_PUBLIC_REST_API_URL || process.env.REST_API_URL;

export const Routes = {
  create: '/folders',
  update: '/folders/%s',
  archive: '/folders/%s/archive',
  restore: '/folders/%s/restore',
  copy: '/folders/%s/copy',
  breadcrumbs: '/folders/%s/breadcrumbs',
  fetch: '/folders/%s',
  fetchMany: '/folders',
  stats: '/folders/%s/stats',
  history: '/folders/%s/history',
  download: '/folders/%s/download',
  favorite: '/folders/%s/favorite'
};

interface CreateInput {
  name: string;
  parent_id?: string;
}

interface CopyInput {
  id: string;
  name: string;
  parent_id?: string;
}

interface UpdateInput {
  id: string;
  name?: string;
  parent_id?: string | null;
}

interface RestoreInput {
  id: string;
  updated_at: string;
}

export const create = async ({ ...props }: CreateInput) => {
  const url = BASE_URL + Routes.create;
  return post({ url, body: JSON.stringify(props) });
};

export const fetch = async ({ id }: { id: string }) => {
  const url = BASE_URL + sprintf(Routes.fetch, id);
  return get({ url });
};

export const fetchManyPaged = async ({ parentId, page }: { parentId?: string; page?: number }) => {
  const url = BASE_URL + Routes.fetchMany + `?page=${page}`;
  return parentId ? get({ url: url + `&parent_id=${parentId}` }) : get({ url });
};

export const copy = async ({ id, ...props }: CopyInput) => {
  const url = BASE_URL + sprintf(Routes.copy, id);
  return post({ url, body: JSON.stringify(props) });
};

export const update = async ({ id, ...props }: UpdateInput) => {
  const url = BASE_URL + sprintf(Routes.update, id);
  return patch({ url, body: JSON.stringify(props) });
};

export const archive = async ({ id }: { id: string }) => {
  const url = BASE_URL + sprintf(Routes.archive, id);
  return post({ url });
};

export const restore = async ({ id, ...props }: RestoreInput) => {
  const url = BASE_URL + sprintf(Routes.restore, id);
  return post({ url, body: JSON.stringify(props) });
};

export const download = async ({ id, headers }: { id: string; headers: HeadersInit }) => {
  const url = BASE_URL + sprintf(Routes.download, id);
  return get({ url, headers });
};

export const fetchBreadcrumbs = async ({ parentId }: { parentId?: string }) => {
  const url = BASE_URL + sprintf(Routes.breadcrumbs, parentId);
  return get({ url });
};

export const getFolderStats = async ({ id }: { id: string }) => {
  const url = BASE_URL + sprintf(Routes.stats, id);
  return get({ url });
};

export const fetchHistory = async ({ id }: { id: string }) => {
  const url = BASE_URL + sprintf(Routes.history, id);
  return get({ url });
};

export const favorite = async ({ id, enabled }: { id: string; enabled: boolean }) => {
  const url = BASE_URL + sprintf(Routes.favorite, id);
  return patch({
    url,
    body: JSON.stringify({ enabled })
  });
};
