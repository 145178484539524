import { ReactNode } from 'react';
import { XCircleIcon } from '@heroicons/react/solid';
import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';
import { Permissionable, PermissionableType } from '@imaging/types';

export default class FolderPermissionDeleteManyHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser, permissionables: Permissionable[]) {
    super(history, user, permissionables);
    this.action = 'removed';
  }

  displayPermissionables(): ReactNode {
    const permissionable = this.permissionables.find(
      (p) =>
        p.permissionable_id === this.history.attributes?.prev.user_id &&
        p.permissionable_type === PermissionableType.USER
    );

    return <span className="font-semibold break-all">{permissionable?.name}</span>;
  }

  displayText(): ReactNode {
    if (this.history.attributes?.prev.folders) {
      return (
        <>
          <b>{this.userName}</b> {this.action} all permissions for {this.displayPermissionables()}
        </>
      );
    }

    return (
      <>
        <b>{this.userName}</b> {this.action} all permissions
      </>
    );
  }

  detailText(): ReactNode {
    if (this.history.attributes?.prev.folders) {
      return (
        <div data-testid="history-detail-text">
          The permissions for{' '}
          <span className="font-semibold break-all">{this.displayPermissionables()}</span> were
          removed from the folders{' '}
          <span className="font-semibold break-all">
            {this.history.attributes?.prev.folders?.join(', ')}
          </span>
          .
        </div>
      );
    }

    return (
      <div data-testid="history-detail-text">
        The folder{' '}
        <span className="font-semibold break-all">{this.history.attributes?.next.folder_name}</span>{' '}
        was made public.
      </div>
    );
  }

  detailIcon(): ReactNode {
    return <XCircleIcon className="h-5 fill-support-error" />;
  }
}
