import { ReactNode } from 'react';
import { UserIcon } from '@heroicons/react/solid';

import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';

export default class FolderContactDeleteHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser) {
    super(history, user);
    this.action = 'un-linked';
  }

  entityType(): string {
    return 'contact';
  }

  detailText(): ReactNode {
    if (this.history.attributes?.migrated) {
      return this.history.attributes?.migrated.description;
    } else {
      return (
        <div data-testid="history-detail-text">
          The {this.entityType()}{' '}
          <span className="font-semibold break-all">
            {this.history.attributes?.prev.contact_name}
          </span>{' '}
          was {this.action}.
        </div>
      );
    }
  }

  detailIcon(): ReactNode {
    return (
      <div className="h-5 rounded-full bg-support-error p-0.5">
        <UserIcon className="text-inverse-01 h-full" />
      </div>
    );
  }
}
