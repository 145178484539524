import type { DragEvent } from 'react';

export const isDroppingFiles = (e: DragEvent) => {
  const items = e.dataTransfer.items;

  e.preventDefault();

  for (let i = 0; i < items.length; i++) {
    const item = items[i].webkitGetAsEntry();

    if (!item?.isDirectory) {
      return true;
    }
  }
  return false;
};
