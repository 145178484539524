import { Children } from 'react';

export const findByType = (children: any, type: string) => {
  const result: any[] = [];

  Children.forEach(children, (child) => {
    const childType = child && child.type && (child.type.displayName || child.type.name);

    if (type.includes(childType)) {
      result.push(child);
    }
  });

  return result[0];
};
