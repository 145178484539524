interface Props {
  className: string;
}

export function RestoreIcon({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4C5 3.44772 4.55228 3 4 3C3.44772 3 3 3.44772 3 4V9C3 9.55228 3.44772 10 4 10H9C9.55228 10 10 9.55228 10 9C10 8.44772 9.55228 8 9 8H6.25428C7.52045 6.18485 9.62222 5 11.9997 5C15.8657 5 18.9997 8.13401 18.9997 12C18.9997 15.866 15.8657 19 11.9997 19C8.43082 19 5.4844 16.3283 5.05394 12.8763C4.9856 12.3282 4.48593 11.9393 3.93789 12.0077C3.38985 12.076 3.00097 12.5757 3.06931 13.1237C3.62305 17.5644 7.40941 21 11.9997 21C16.9703 21 20.9997 16.9706 20.9997 12C20.9997 7.02944 16.9703 3 11.9997 3C9.17155 3 6.64898 4.30489 5 6.34252V4Z"
        fill="#475363"
      />
    </svg>
  );
}
