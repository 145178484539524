import { ReactNode } from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';

import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';

export default class FolderUpdateHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser) {
    super(history, user);
    this.action = 'updated';
  }

  actionText(): string {
    const userFavorite = this.history.attributes?.changes?.favorites?.find(
      (favorite) => favorite.user_id === this.user.userId
    );
    return !userFavorite ? this.action : userFavorite.enabled ? 'favorited' : 'unfavorited';
  }

  detailText(): ReactNode {
    return (
      <div data-testid="history-detail-text">
        The folder{' '}
        <span className="font-semibold break-all">{this.history.attributes?.prev.name}</span> was{' '}
        {this.actionText()}.
      </div>
    );
  }

  detailIcon(): ReactNode {
    return <InformationCircleIcon className="h-5 fill-support-info" />;
  }
}
