import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  Dispatch,
  SetStateAction
} from 'react';
import { Uppy, UploadResult, UppyFile } from '@uppy/core';
import { datadogRum } from '@datadog/browser-rum';
import { omit } from 'lodash';

import { InvalidFile } from '../types';

interface ProviderProps {
  client: Uppy;
  children: ReactNode;
}

interface ContextType {
  uppy: Uppy;
  invalidFiles: InvalidFile[];
  resetContext(): void;
  setInvalidFiles: Dispatch<SetStateAction<InvalidFile[]>>;
  currentFolder: string | undefined;
  setCurrentFolder: Dispatch<SetStateAction<string | undefined>>;
  totalFiles: number;
  setTotalFiles: Dispatch<SetStateAction<number>>;
  cancelUpload: boolean;
  setCancelUpload: Dispatch<SetStateAction<boolean>>;
}

const UppyContext = createContext<ContextType | undefined>(undefined);

function UppyProvider({ client, children }: ProviderProps) {
  const [, setUploadResults] = useState<UploadResult<object, object>>();
  const [, setRemovedFile] = useState<UppyFile | undefined>();
  const [invalidFiles, setInvalidFiles] = useState<InvalidFile[]>([]);
  const [currentFolder, setCurrentFolder] = useState<string>();
  const [totalFiles, setTotalFiles] = useState(0);
  const [cancelUpload, setCancelUpload] = useState(false);

  const resetContext = () => {
    setCancelUpload(false);
    setUploadResults(undefined);
    setRemovedFile(undefined);
    setInvalidFiles([]);
    setTotalFiles(0);
    setCurrentFolder(undefined);
    client.cancelAll({ reason: 'user' });
  };

  const value = {
    uppy: client,
    invalidFiles,
    resetContext,
    setInvalidFiles,
    currentFolder,
    setCurrentFolder,
    totalFiles,
    setTotalFiles,
    cancelUpload,
    setCancelUpload
  };

  useEffect(() => {
    client.on('file-removed', (file: UppyFile) => {
      setRemovedFile(file);
    });

    client.on('complete', (result) => {
      setUploadResults(result);
    });

    client.on('upload-error', (file, error, response) => {
      const exception = {
        error,
        response,
        file: omit(file, 'data')
      };
      datadogRum.addError(exception);
    });
  }, []);

  return <UppyContext.Provider value={value}>{children}</UppyContext.Provider>;
}

function useUppyContext() {
  const context = useContext(UppyContext);
  if (context === undefined) {
    throw new Error('useUppyContext must be used within a UppyProvider');
  }
  return context;
}

export { UppyProvider, useUppyContext };
