import { IntegrationGateway } from '@/core/gateways';

export async function fetchIntegrations() {
  const response = await IntegrationGateway.fetch();

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Could not get integrations.');
  }
}
