import { PermissionProps } from '@imaging/types';
import { get, post, destroy as destroyRequest } from '../http';
import { sprintf } from '@/core/utils';

const BASE_URL = process.env.NEXT_PUBLIC_REST_API_URL || process.env.REST_API_URL;

export const Routes = {
  create: '/folder_permissions',
  fetch: '/folder_permissions/%s',
  destroy: '/folder_permissions/%s',
  destroyMany: '/folders/%s/permissions',
  destroyByUser: '/folder_permissions/by_user?user_id=%s'
};

interface CreateInput {
  folder_id: string;
  permissions: PermissionProps[];
}

export const create = async ({ ...props }: CreateInput) => {
  const url = BASE_URL + Routes.create;
  return post({ url, body: JSON.stringify(props) });
};

export const fetch = async ({ id }: { id: string }) => {
  const url = BASE_URL + sprintf(Routes.fetch, id);
  return get({ url });
};

export const destroy = async ({ id }: { id: string }) => {
  const url = BASE_URL + sprintf(Routes.destroy, id);
  return destroyRequest({ url });
};

export const deleteMany = async ({ folder_id }: { folder_id: string }) => {
  const url = BASE_URL + sprintf(Routes.destroyMany, folder_id);
  return destroyRequest({ url });
};

export const deleteByUser = async ({ user_id }: { user_id: number }) => {
  const url = BASE_URL + sprintf(Routes.destroyByUser, user_id);
  return destroyRequest({ url });
};
