import { post } from '../http';
import { Database } from '../types';

export const setCurrentDatabase = async ({ database }: { database: Database }) => {
  const url = process.env.NEXT_PUBLIC_FRONT_END_BASE_URL + '/api/auth/switch-database';
  return post({
    url,
    body: JSON.stringify({ currentDatabaseId: database.databaseId })
  });
};
