import { ReactNode } from 'react';
import { ArrowCircleDownIcon } from '@heroicons/react/solid';

import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';

export default class DownloadHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser) {
    super(history, user);
    this.action = 'downloaded';
  }

  displayText(): ReactNode {
    return (
      <>
        <b>{this.userName}</b> {this.action}
      </>
    );
  }

  detailText(): string {
    if (this.history.attributes?.migrated) {
      return this.history.attributes?.migrated.description;
    } else {
      return `${this.userFullName()} ${this.action}`;
    }
  }

  detailIcon(): ReactNode {
    return <ArrowCircleDownIcon className="h-5 fill-support-success" />;
  }
}
