import { post } from '../http';

const BASE_URL = process.env.NEXT_PUBLIC_REST_API_URL as string;

export const Routes = {
  move: '/bulk/move',
  color: '/bulk/color',
  archive: '/bulk/archive',
  restore: '/bulk/restore',
  destroy: '/bulk/destroy'
};

interface BulkInput {
  file_ids?: string[];
  folder_ids?: string[];
}

interface BulkMove extends BulkInput {
  parent_id?: string | null;
}

interface BulkColor {
  folder_ids: string[];
  color: string;
}

export const move = async ({ ...props }: BulkMove) => {
  const url = BASE_URL + Routes.move;
  return post({ url, body: JSON.stringify(props) });
};

export const setColor = async ({ ...props }: BulkColor) => {
  const url = BASE_URL + Routes.color;
  return post({ url, body: JSON.stringify(props) });
};

export const archive = async ({ ...props }: BulkInput) => {
  const url = BASE_URL + Routes.archive;
  return post({ url, body: JSON.stringify(props) });
};

export const restore = async ({ ...props }: BulkInput) => {
  const url = BASE_URL + Routes.restore;
  return post({ url, body: JSON.stringify(props) });
};

export const destroy = async ({ ...props }: BulkInput) => {
  const url = BASE_URL + Routes.destroy;
  return post({ url, body: JSON.stringify(props) });
};
