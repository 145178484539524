import React, { ComponentProps, forwardRef } from 'react';

import { classNames, sizeClasses } from '@/core/utils';
import { ButtonSizes } from '@/core/types';

interface Props {
  size: ButtonSizes;
}

const SecondaryButton = forwardRef<HTMLButtonElement, Props & ComponentProps<'button'>>(
  (props, ref) => {
    const { disabled, size, className, children, ...rest } = props;

    const disabledClasses =
      'disabled:border-disabled-01 disabled:cursor-not-allowed disabled:text-03';
    const focusClasses =
      'focus-visible:border-focus-01 focus-visible:ring-focus-ring focus-visible:ring-4 focus:outline-none';

    return (
      <button
        type="button"
        ref={ref}
        disabled={disabled}
        {...rest}
        className={classNames(
          'flex items-center space-x-2 text-02',
          'button rounded-full bg-transparent inset border border-interactive-02 hover:border-hover-02',
          sizeClasses[size],
          disabledClasses,
          focusClasses,
          className
        )}
      >
        {children}
      </button>
    );
  }
);

export default SecondaryButton;
