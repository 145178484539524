import { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction } from 'react';
import { SearchFilters } from '@/core/types';

interface ProviderProps {
  children: ReactNode;
}

interface ContextType {
  query: string;
  searchTerm: string;
  isSearching: boolean;
  filterItems: SearchFilters;
  clearSearch(): void;
  setQuery: Dispatch<SetStateAction<string>>;
  setIsSearching: Dispatch<SetStateAction<boolean>>;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  setFilterItems: Dispatch<SetStateAction<SearchFilters>>;
}

const SearchContext = createContext<ContextType | undefined>(undefined);

function SearchProvider({ children }: ProviderProps) {
  const [query, setQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterItems, setFilterItems] = useState<SearchFilters>({});

  const clearSearch = () => {
    setIsSearching(false);
    setQuery('');
    setSearchTerm('');
  };

  const value = {
    query,
    isSearching,
    searchTerm,
    filterItems,
    setQuery,
    setIsSearching,
    setSearchTerm,
    setFilterItems,
    clearSearch
  };

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
}

function useSearchContext() {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }
  return context;
}

export { SearchProvider, useSearchContext };
