import { ReactNode } from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';

import { DatabaseUser, HistoryItem } from '@/core/types';
import { Permissionable } from '@imaging/types';

export abstract class EntityHistory {
  history: HistoryItem;
  permissionables: Permissionable[];
  user: DatabaseUser;
  userName: string;
  action!: string;

  constructor(history: HistoryItem, user: DatabaseUser, permissionables?: Permissionable[]) {
    this.history = history;
    this.permissionables = permissionables ? permissionables : [];
    this.user = user;
    this.userName = user ? `${this.user.firstName} ${this.user.lastName}` : '';
  }

  entityType(): string {
    return this.history.entity_type;
  }

  userFullName(): string {
    if (!this.user) return '';
    return `${this.user.firstName} ${this.user.lastName}`;
  }

  displayText(): ReactNode {
    return (
      <>
        <b>{this.userName}</b> {this.action} a {this.entityType()}
      </>
    );
  }

  detailText(): ReactNode {
    if (this.history.attributes?.migrated) {
      return this.history.attributes?.migrated.description;
    } else {
      return (
        <div data-testid="history-detail-text">
          The {this.history.entity_type}{' '}
          <span className="font-semibold break-all">{this.history.attributes?.prev.name}</span> was{' '}
          {this.action}.
        </div>
      );
    }
  }

  detailIcon(): ReactNode {
    return <QuestionMarkCircleIcon className="h-7 fill-icon-01" />;
  }
}
