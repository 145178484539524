import { get, post } from '../http';

interface Filters {
  entity_types: string[];
  actions: string[];
  users: number[];
  date?: { start: string; end: string };
}

export const fetch = async (page: number, filters?: Filters) => {
  const url = process.env.NEXT_PUBLIC_API_BASE_URL + '/api/rest/history?page=' + page;
  return post({ url, body: JSON.stringify({ filters }) });
};

export const fetchCSV = async (filters?: Filters) => {
  const url = process.env.NEXT_PUBLIC_API_BASE_URL + '/api/rest/history/export';
  return post({ url, body: JSON.stringify({ filters }) });
};

export const stats = (start?: string, end?: string) => {
  const url =
    process.env.NEXT_PUBLIC_API_BASE_URL + '/api/rest/history/stats?start=' + start + '&end=' + end;
  return get({ url });
};

export const login = () => {
  const url = process.env.NEXT_PUBLIC_API_BASE_URL + '/api/rest/private/user_event/login';
  return get({ url });
};

export const logout = () => {
  const url = process.env.NEXT_PUBLIC_API_BASE_URL + '/api/rest/private/user_event/logout';
  return get({ url });
};
