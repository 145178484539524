import { DotsHorizontalIcon } from '@heroicons/react/outline';
import Link from 'next/link';

interface Props {
  href?: string;
  onClick?(): void;
}

export function BreadcrumbsDotIcon({ href, onClick }: Props) {
  if (href) {
    return (
      <Link
        href={href}
        className="flex items-center justify-center h-8 w-8 rounded hover:bg-hover-03 cursor-pointer focus-visible:ring-focus-ring focus-visible:ring-4 focus:outline-none"
        shallow
      >
        <DotsHorizontalIcon className="h-5 w-5 text-icon-01" data-testid="breadcrumbs-dot-icon" />
      </Link>
    );
  } else {
    return (
      <DotsHorizontalIcon
        className="h-5 w-5 text-icon-01 hover:bg-hover-03 cursor-pointer"
        onClick={onClick}
      />
    );
  }
}
