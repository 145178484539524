import { ComponentProps } from 'react';

export function SolidCollectionX({ ...props }: ComponentProps<'svg'>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 3.00001C6.73478 3.00001 6.48043 3.10536 6.29289 3.2929C6.10536 3.48044 6 3.73479 6 4.00001C6 4.26522 6.10536 4.51958 6.29289 4.70711C6.48043 4.89465 6.73478 5.00001 7 5.00001H10C10.2652 5.00001 10.5196 4.89465 10.7071 4.70711C10.8946 4.51958 11 4.26522 11 4.00001C11 3.73479 10.8946 3.48044 10.7071 3.2929C10.5196 3.10536 10.2652 3.00001 10 3.00001H7ZM4 7.00001C4 6.73479 4.10536 6.48044 4.29289 6.2929C4.48043 6.10536 4.73478 6.00001 5 6.00001H10C10.2652 6.00001 10.5196 6.10536 10.7071 6.2929C10.8946 6.48044 11 6.73479 11 7.00001C11 7.26522 10.8946 7.51958 10.7071 7.70711C10.5196 7.89465 10.2652 8.00001 10 8.00001H5C4.73478 8.00001 4.48043 7.89465 4.29289 7.70711C4.10536 7.51958 4 7.26522 4 7.00001ZM2 11C2 10.4696 2.21071 9.96087 2.58579 9.58579C2.96086 9.21072 3.46957 9.00001 4 9.00001H10C10.5 9.00001 11 9.50001 11 10C11 9.62883 11 10.5 11 10C11.563 10 16 10 17 10C17.5 10 18 10.5 18 11V15C18 15.5304 17.7893 16.0391 17.4142 16.4142C17.0391 16.7893 16.5304 17 16 17H4C3.46957 17 2.96086 16.7893 2.58579 16.4142C2.21071 16.0391 2 15.5304 2 15V11Z"
        fill="#475363"
      />
      <path
        d="M12.9998 3.01246C12.7346 3.01246 12.4803 3.11778 12.2928 3.30525C12.1053 3.49278 12 3.74709 12 4.01225C12 4.27741 12.1053 4.53172 12.2928 4.71925L13.5858 6.01225L12.2928 7.30525C12.1106 7.49385 12.0098 7.74646 12.0121 8.00865C12.0144 8.27085 12.1196 8.52166 12.305 8.70707C12.4904 8.89248 12.7412 8.99765 13.0034 8.99992C13.2656 9.0022 13.5182 8.90141 13.7068 8.71925L14.9998 7.42625L16.2928 8.71925C16.4814 8.90141 16.734 9.0022 16.9962 8.99992C17.2584 8.99765 17.5092 8.89248 17.6946 8.70707C17.88 8.52166 17.9852 8.27085 17.9875 8.00865C17.9897 7.74646 17.8889 7.49385 17.7068 7.30525L16.4138 6.01225L17.7068 4.71925C17.8023 4.627 17.8785 4.51666 17.9309 4.39465C17.9833 4.27265 18.0109 4.14143 18.012 4.00865C18.0132 3.87587 17.9879 3.74419 17.9376 3.6213C17.8873 3.4984 17.8131 3.38675 17.7192 3.29286C17.6253 3.19896 17.5136 3.12471 17.3907 3.07443C17.2678 3.02415 17.1362 2.99885 17.0034 3C16.8706 3.00115 16.7394 3.02874 16.6174 3.08115C16.4954 3.13356 16.385 3.20974 16.2928 3.30525L14.9998 4.59825L13.7068 3.30525C13.5193 3.11778 13.265 3.01246 12.9998 3.01246Z"
        fill="#475363"
      />
    </svg>
  );
}
