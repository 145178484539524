import { ReactNode } from 'react';
import { UserIcon } from '@heroicons/react/solid';

import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';

export default class FolderContactCreateHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser) {
    super(history, user);
    this.action = 'linked';
  }

  entityType(): string {
    return 'contact';
  }

  detailText(): ReactNode {
    if (this.history.attributes?.migrated) {
      return this.history.attributes?.migrated.description;
    } else {
      return (
        <div data-testid="history-detail-text">
          The {this.entityType()}{' '}
          <span className="font-semibold break-all">
            {this.history.attributes?.next.contact_name}
          </span>{' '}
          was {this.action} to the folder{' '}
          <span className="font-semibold break-all">
            {this.history.attributes?.changes.folder.name}.
          </span>
        </div>
      );
    }
  }

  detailIcon(): ReactNode {
    return (
      <div className="h-5 rounded-full bg-support-success p-0.5">
        <UserIcon className="text-inverse-01 h-full" />
      </div>
    );
  }
}
