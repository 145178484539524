import { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction } from 'react';
import { DatabaseUser } from '@/core/types';

interface ProviderProps {
  children: ReactNode;
}

interface ContextType {
  isUpdateDialogOpen: boolean;
  isPasswordDialogOpen: boolean;
  isDisableDialogOpen: boolean;
  isEnableDialogOpen: boolean;
  isUnlockDialogOpen: boolean;
  isPermissionDialogOpen: boolean;
  selectedMember: DatabaseUser | undefined;
  setIsUpdateDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsPasswordDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsDisableDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsUnlockDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsPermissionDialogOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedMember: Dispatch<SetStateAction<DatabaseUser | undefined>>;
}

const MemberContext = createContext<ContextType | undefined>(undefined);

function MemberProvider({ children }: ProviderProps) {
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const [isDisableDialogOpen, setIsDisableDialogOpen] = useState(false);
  const [isEnableDialogOpen, setIsEnableDialogOpen] = useState(false);
  const [isUnlockDialogOpen, setIsUnlockDialogOpen] = useState(false);
  const [isPermissionDialogOpen, setIsPermissionDialogOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<DatabaseUser>();

  const value = {
    isUpdateDialogOpen,
    isPasswordDialogOpen,
    isDisableDialogOpen,
    isEnableDialogOpen,
    isPermissionDialogOpen,
    selectedMember,
    setIsUpdateDialogOpen,
    setIsPasswordDialogOpen,
    setIsDisableDialogOpen,
    setIsEnableDialogOpen,
    setIsPermissionDialogOpen,
    isUnlockDialogOpen,
    setIsUnlockDialogOpen,
    setSelectedMember
  };

  return <MemberContext.Provider value={value}>{children}</MemberContext.Provider>;
}

function useMemberContext() {
  const context = useContext(MemberContext);
  if (context === undefined) {
    throw new Error('useMemberContext must be used within a UiProvider');
  }
  return context;
}

export { MemberProvider, useMemberContext };
