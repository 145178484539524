export enum PermissionableType {
  TEAM = 'team',
  USER = 'user'
}

export interface PermissionProps {
  permissionable_type: PermissionableType.TEAM | PermissionableType.USER;
  permissionable_id: number;
}

export interface Permissionable extends PermissionProps {
  name: string;
  disabled: boolean;
}