import { ReactNode } from 'react';
import { ArrowCircleDownIcon } from '@heroicons/react/solid';

import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';

export default class FolderDownloadHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser) {
    super(history, user);
    this.action = 'downloaded';
  }

  detailIcon(): ReactNode {
    return <ArrowCircleDownIcon className="h-5 fill-support-success" />;
  }
}
