import {
  FolderOpenIcon,
  ClockIcon,
  ArchiveIcon,
  ChartSquareBarIcon,
  UsersIcon,
  StarIcon
} from '@heroicons/react/outline';

type NavigationItem = {
  name: string;
  icon: any;
  route: string;
  current(name: string): boolean;
};

export const navigation: NavigationItem[] = [
  {
    name: 'Home',
    icon: FolderOpenIcon,
    current: (name: string) => name === '/home',
    route: '/home'
  },
  {
    name: 'Recent',
    icon: ClockIcon,
    current: (name: string) => name === '/recent',
    route: '/recent'
  },
  {
    name: 'Favorites',
    icon: StarIcon,
    current: (name: string) => name === '/favorites',
    route: '/favorites'
  },
  {
    name: 'Shared',
    icon: UsersIcon,
    current: (name: string) => name === '/shared',
    route: '/shared'
  },
  {
    name: 'Archive',
    icon: ArchiveIcon,
    current: (name: string) => name === '/archive',
    route: '/archive'
  },
  {
    name: 'History',
    icon: ChartSquareBarIcon,
    current: (name: string) => name === '/history',
    route: '/history'
  }
];
