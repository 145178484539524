import { ReactNode } from 'react';
import { uniqBy } from 'lodash';

import {
  ImageFileIcon,
  DocumentFileIcon,
  VideoFileIcon,
  PdfFileIcon,
  SpreadsheetFileIcon,
  AudioFileIcon,
  PresentationFileIcon,
  OtherFileIcon
} from '@/components/icons';
import { File as FileEntity, RawFile } from '@/core/types';

const extensionMap: Record<string, { Component: ReactNode; type: string }> = {
  avi: { Component: <VideoFileIcon />, type: 'Video' },
  mov: { Component: <VideoFileIcon />, type: 'Video' },
  mp4: { Component: <VideoFileIcon />, type: 'Video' },
  gif: { Component: <ImageFileIcon />, type: 'Image' },
  jpg: { Component: <ImageFileIcon />, type: 'Image' },
  jpeg: { Component: <ImageFileIcon />, type: 'Image' },
  png: { Component: <ImageFileIcon />, type: 'Image' },
  tif: { Component: <ImageFileIcon />, type: 'Image' },
  tiff: { Component: <ImageFileIcon />, type: 'Image' },
  txt: { Component: <DocumentFileIcon />, type: 'Document' },
  doc: { Component: <DocumentFileIcon />, type: 'Document' },
  docx: { Component: <DocumentFileIcon />, type: 'Document' },
  pdf: { Component: <PdfFileIcon />, type: 'PDF' },
  xls: { Component: <SpreadsheetFileIcon />, type: 'Spreadsheet' },
  xlsx: { Component: <SpreadsheetFileIcon />, type: 'Spreadsheet' },
  csv: { Component: <SpreadsheetFileIcon />, type: 'Spreadsheet' },
  mp3: { Component: <AudioFileIcon />, type: 'Audio' },
  wav: { Component: <AudioFileIcon />, type: 'Audio' },
  ppt: { Component: <PresentationFileIcon />, type: 'Presentation' },
  pptx: { Component: <PresentationFileIcon />, type: 'Presentation' },
  default: { Component: <OtherFileIcon />, type: 'Other' }
};

export const transformDocSize = (docSize: number, truncate = false) => {
  if (docSize === 0 || !docSize) {
    return '0 KB';
  }

  let i = -1;
  const byteUnits = [' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'];

  do {
    docSize = docSize / 1024;
    i++;
  } while (docSize >= 1024);

  if (truncate) {
    return Math.max(docSize, 0.1) + byteUnits[i];
  } else {
    return Math.max(docSize, 0.1).toFixed(1) + byteUnits[i];
  }
};

export const iconForExtension = (extension: string) => {
  const lcExtension = extension.toLowerCase();
  if (lcExtension in extensionMap) {
    return extensionMap[lcExtension];
  } else {
    return extensionMap['default'];
  }
};

export const getType = (extension?: string) => {
  if (extension) {
    return iconForExtension(extension).type;
  } else {
    return 'Folder';
  }
};

export const iconByFileType = () => {
  return uniqBy(Object.values(extensionMap), 'type');
};

export const maxFileSize = 104857600;

export const previewableTypes = [
  'bib',
  'doc',
  'xml',
  'docx',
  'fodt',
  'html',
  'ltx',
  'txt',
  'odt',
  'ott',
  'pdb',
  'pdf',
  'psw',
  'rtf',
  'sdw',
  'stw',
  'sxw',
  'uot',
  'vor',
  'wps',
  'epub',
  'png',
  'bmp',
  'emf',
  'eps',
  'fodg',
  'gif',
  'jpg',
  'jpeg',
  'met',
  'odd',
  'otg',
  'pbm',
  'pct',
  'pgm',
  'ppm',
  'ras',
  'std',
  'svg',
  'svm',
  'swf',
  'sxd',
  'sxd',
  'sxw',
  'tiff',
  'xhtml',
  'xpm',
  'fodp',
  'potm',
  'pot',
  'pptx',
  'pps',
  'ppt',
  'pwp',
  'sda',
  'sdd',
  'sti',
  'sxi',
  'uop',
  'wmf',
  'csv',
  'dbf',
  'dif',
  'fods',
  'ods',
  'ots',
  'pxl',
  'sdc',
  'slk',
  'stc',
  'sxc',
  'uos',
  'xls',
  'xlt',
  'xlsx',
  'tif',
  'odp',
  'odg',
  'dotx',
  'xltx'
];

export const getFileExtension = ({ name }: File) => {
  const splitName = name.split('.');
  return splitName.length > 1 ? splitName.pop()?.toLowerCase() : false;
};

export const removeFileExtension = (fileName: string) => {
  return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
};

export const buildFileEntity = (file: RawFile, userId?: number): FileEntity => {
  return {
    ...file,
    favorite: file.favorites.some((favorite) => favorite.user_id === userId && favorite.enabled)
  };
};
