import { parsePhoneNumber } from 'libphonenumber-js';

export const formattedPhone = (phone: any) => {
  const parsedNumber = parsePhoneNumber(`+${phone.country_code}${phone.number}`);

  if (!parsedNumber) return null;

  if (phone.country_code === 1) {
    return parsedNumber.formatNational();
  } else {
    return parsedNumber.formatInternational();
  }
};
