import { ReactNode } from 'react';
import { PlusCircleIcon } from '@heroicons/react/solid';

import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';

export default class AppliedIndexCreateManyHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser) {
    super(history, user);
    this.action = 'applied';
  }

  private indexNames() {
    return this.history.attributes?.next.index_values
      .map((indexValue: any) => indexValue.name)
      .join(', ');
  }

  private indexValues() {
    return this.history.attributes?.next.index_values
      .map((indexValue: any) => indexValue.value)
      .join(', ');
  }

  detailText(): ReactNode {
    if (this.history.attributes?.migrated) {
      return this.history.attributes?.migrated.description;
    } else if (this.history.attributes?.next.index_values?.length > 1) {
      return (
        <div data-testid="history-detail-text">
          The indexes: <span className="font-semibold break-all">{this.indexNames()}</span>, were{' '}
          {this.action} with values: {this.indexValues()}.
        </div>
      );
    } else {
      return (
        <div data-testid="history-detail-text">
          The index: <span className="font-semibold break-all">{this.indexNames()}</span>, was{' '}
          {this.action} with the value: {this.indexValues()}.
        </div>
      );
    }
  }

  displayText(): ReactNode {
    if (this.history.attributes?.next.index_values?.length > 1) {
      return (
        <>
          <b>{this.userName}</b> {this.action} multiple indexes
        </>
      );
    } else {
      return (
        <>
          <b>{this.userName}</b> {this.action} an index
        </>
      );
    }
  }

  detailIcon(): ReactNode {
    return <PlusCircleIcon className="h-5 fill-support-success" />;
  }
}
