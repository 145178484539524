import React, { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';

import ActionPanel from '../components/action-panel';
import {
  NotificationAction,
  notificationReducer,
  NotificationState
} from '../reducers/notification';

interface ProviderProps {
  children: ReactNode;
}

interface ContextType {
  state: NotificationState;
  dispatch: Dispatch<NotificationAction>;
}

const NotificationContext = createContext<ContextType | undefined>(undefined);

function NotificationProvider({ children }: ProviderProps) {
  const [state, dispatch] = useReducer(notificationReducer, { open: false });

  const value = {
    state,
    dispatch
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <ActionPanel open={state.open}>{state.component}</ActionPanel>
    </NotificationContext.Provider>
  );
}

function useNotificationContext() {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotificationContext must be used within a NotificationProvider');
  }
  return context;
}

export { NotificationProvider, useNotificationContext };
