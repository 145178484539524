import * as History from '../components/history';
import { HistoryItem } from '../types';

const {
  FolderCreateHistory,
  FolderUpdateHistory,
  FolderCopyHistory,
  FolderRenameHistory,
  FolderMoveHistory,
  FolderRestoreHistory,
  FolderArchiveHistory,
  FolderDeleteHistory,
  FolderDownloadHistory,
  FolderContactCreateHistory,
  FolderContactDeleteHistory,
  FolderPermissionCreateManyHistory,
  FolderPermissionDeleteHistory,
  FolderPermissionDeleteManyHistory,
  FileCreateHistory,
  FileUpdateHistory,
  FileRenameHistory,
  FileMoveHistory,
  FileRestoreHistory,
  FileArchiveHistory,
  FileDeleteHistory,
  FileDownloadHistory,
  FilePreviewHistory,
  LoginHistory,
  LogoutHistory,
  DownloadHistory,
  AppliedIndexCreateManyHistory,
  AppliedIndexUpdateHistory,
  AppliedIndexRestoreHistory,
  AppliedIndexArchiveHistory,
  AppliedIndexDeleteHistory
} = History;

const historyClassMap: Record<string, Record<string, any>> = {
  folder: {
    create: FolderCreateHistory,
    update: FolderUpdateHistory,
    copy: FolderCopyHistory,
    rename: FolderRenameHistory,
    move: FolderMoveHistory,
    restore: FolderRestoreHistory,
    archive: FolderArchiveHistory,
    delete: FolderDeleteHistory,
    download: FolderDownloadHistory
  },
  folder_contact: { create: FolderContactCreateHistory, delete: FolderContactDeleteHistory },
  file: {
    create: FileCreateHistory,
    update: FileUpdateHistory,
    rename: FileRenameHistory,
    move: FileMoveHistory,
    restore: FileRestoreHistory,
    archive: FileArchiveHistory,
    delete: FileDeleteHistory,
    download: FileDownloadHistory,
    preview: FilePreviewHistory
  },
  user: { login: LoginHistory, logout: LogoutHistory, download: DownloadHistory },
  applied_index: {
    create_many: AppliedIndexCreateManyHistory,
    update: AppliedIndexUpdateHistory,
    restore: AppliedIndexRestoreHistory,
    archive: AppliedIndexArchiveHistory,
    delete: AppliedIndexDeleteHistory
  },
  folder_permission: {
    create_many: FolderPermissionCreateManyHistory,
    delete: FolderPermissionDeleteHistory,
    delete_many: FolderPermissionDeleteManyHistory
  }
};

export const buildHistoryClass = (history: HistoryItem) => {
  return historyClassMap[history.entity_type][history.action];
};
