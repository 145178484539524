import { HomeIcon } from '@heroicons/react/solid';
import Link from 'next/link';
import { TertiaryButton } from '@/components/buttons';

interface Props {
  href?: string;
  onClick?(): void;
}

export function BreadcrumbsHomeIcon({ href, onClick }: Props) {
  if (href) {
    return (
      <TertiaryButton size="smallIcon">
        <Link className="focus:outline-none" data-testid="breadcrumb-link-home" href={href}>
          <HomeIcon className="h-5 w-5 text-icon-01" data-testid="breadcrumbs-home-icon" />
        </Link>
      </TertiaryButton>
    );
  } else {
    return (
      <TertiaryButton size="smallIcon" data-testid="breadcrumb-button-home" onClick={onClick}>
        <HomeIcon
          className="text-icon-01 h-5 w-5 cursor-pointer"
          data-testid="breadcrumbs-home-icon-modal"
        />
      </TertiaryButton>
    );
  }
}
