export interface PresignedUrl {
  url: string;
}

export enum Method {
  GET = 'GET',
  PUT = 'PUT'
}

export enum ContentDisposition {
  INLINE = 'inline',
  ATTACHMENT = 'attachment'
}
