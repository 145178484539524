import { post } from '@/core/http';

const BASE_URL = process.env.REST_API_URL as string;

const Routes = {
  index: '/download'
};

export const download = async ({ body, headers }: { body: BodyInit; headers: HeadersInit }) => {
  const url = BASE_URL + Routes.index;
  return post({ body, url, headers });
};
