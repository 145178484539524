import React, { ComponentProps, forwardRef } from 'react';

import { classNames, sizeClasses } from '@/core/utils';
import { ButtonSizes } from '@/core/types';

interface Props {
  size: ButtonSizes;
}

const PrimaryButton = forwardRef<HTMLButtonElement, Props & ComponentProps<'button'>>(
  (props, ref) => {
    const { disabled, size, className, children, ...rest } = props;
    const disabledClasses =
      'disabled:bg-disabled-01 disabled:border-disabled-01 disabled:cursor-not-allowed disabled:text-03';
    const focusClasses =
      'focus-visible:border-focus-01 focus-visible:ring-focus-ring focus-visible:ring-4 focus:outline-none';

    return (
      <button
        type="button"
        ref={ref}
        disabled={disabled}
        {...rest}
        className={classNames(
          `flex space-x-2 items-center button rounded-full text-inverse-01 bg-interactive-01 hover:bg-hover-01`,
          sizeClasses[size],
          disabledClasses,
          focusClasses,
          className
        )}
      >
        {children}
      </button>
    );
  }
);

export default PrimaryButton;
