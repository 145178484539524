const environment = process.env.ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT;

export const isDeployed = ['development', 'review', 'production', 'rc'].includes(
  environment as string
);

export const isProduction = environment === 'production' || environment === 'rc';

export const isLocal = environment === 'local';

export const isTest = environment === 'test';
