import { ReactNode } from 'react';
import { NotificationActionType } from './action-types';

export interface NotificationState {
  open: boolean;
  component?: ReactNode;
}

export interface NotificationAction {
  type: NotificationActionType;
  payload?: Record<string, any>;
}

export function notificationReducer(state: NotificationState, action: NotificationAction) {
  switch (action.type) {
    case NotificationActionType.OpenPanel:
      return { ...state, open: true, component: action.payload?.component };
    case NotificationActionType.ClosePanel:
      return { ...state, open: false, component: undefined };
    default:
      return state;
  }
}
