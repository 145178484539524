import { ReactNode } from 'react';
import { MinusCircleIcon } from '@heroicons/react/solid';

import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';

export default class AppliedIndexArchiveHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser) {
    super(history, user);
    this.action = 'archived';
  }

  displayText(): ReactNode {
    return (
      <>
        <b>{this.userName}</b> {this.action} an index
      </>
    );
  }

  detailText(): ReactNode {
    return (
      <div data-testid="history-detail-text">
        The index{' '}
        <span className="font-semibold break-all">{this.history.attributes?.next.name}</span> with
        value {this.history.attributes?.prev.value} was {this.action}.
      </div>
    );
  }

  detailIcon(): ReactNode {
    return <MinusCircleIcon className="h-5 fill-support-error" />;
  }
}
