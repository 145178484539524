import { get, patch, post, destroy as destroyRequest } from '../http';
import { sprintf } from '@/core/utils';

const BASE_URL = process.env.NEXT_PUBLIC_REST_API_URL as string;

export const Routes = {
  appliedValues: '/index_fields/%s/applied_values',
  fetch: '/index_fields',
  create: '/index_fields',
  update: '/index_fields/%s',
  archive: '/index_fields/%s/archive',
  restore: '/index_fields/%s/restore',
  undoDelete: '/index_fields/%s/undo-delete',
  destroy: '/index_fields/%s'
};

interface CreateInput {
  name: string;
  description?: string;
  type: string;
  values?: Record<string, any>;
}

interface UpdateInput {
  id: string;
  name?: string;
  description?: string;
  values?: Record<string, any>;
}

interface ArchiveInput {
  id: string;
}

export const fetch = async () => {
  const restUrl = BASE_URL + Routes.fetch;
  return get({ url: restUrl });
};

export const appliedValues = async ({ id }) => {
  const restUrl = BASE_URL + sprintf(Routes.appliedValues, id);
  return get({ url: restUrl });
};

export const create = async ({ ...props }: CreateInput) => {
  const restUrl = BASE_URL + Routes.create;
  return post({ url: restUrl, body: JSON.stringify(props) });
};

export const update = async ({ id, ...props }: UpdateInput) => {
  const restUrl = BASE_URL + sprintf(Routes.update, id);
  return patch({ url: restUrl, body: JSON.stringify(props) });
};

export const archive = async ({ id }: ArchiveInput) => {
  const restUrl = BASE_URL + sprintf(Routes.archive, id);
  return post({ url: restUrl });
};

export const destroy = async ({ id }: { id: string }) => {
  const restUrl = BASE_URL + sprintf(Routes.destroy, id);
  return destroyRequest({ url: restUrl });
};

export const restore = async ({ id }: { id: string }) => {
  const restUrl = BASE_URL + sprintf(Routes.restore, id);
  return post({ url: restUrl });
};

export const undoDelete = async ({ id }: { id: string }) => {
  const restUrl = BASE_URL + sprintf(Routes.undoDelete, id);
  return post({ url: restUrl });
};
