import { patch } from '../http';

const BASE_URL = process.env.NEXT_PUBLIC_REST_API_URL || process.env.REST_API_URL;

export const Routes = {
  upsert: '/colors'
};

interface UpdateInput {
  folder_id: string;
  color: string;
}

export const setColor = async ({ folder_id, color }: UpdateInput) => {
  const url = BASE_URL + Routes.upsert;
  return patch({ url, body: JSON.stringify({ folder_id, color }) });
};
