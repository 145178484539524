import { ReactNode } from 'react';
import { ArrowCircleLeftIcon } from '@heroicons/react/solid';

import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';

export default class FileRestoreHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser) {
    super(history, user);
    this.action = 'restored';
  }

  detailText(): ReactNode {
    return (
      <div data-testid="history-detail-text">
        The file{' '}
        <span className="font-semibold break-all">{this.history.attributes?.prev.name}</span> was{' '}
        {this.action}.
      </div>
    );
  }

  detailIcon(): ReactNode {
    return <ArrowCircleLeftIcon className="h-5 fill-support-success" />;
  }
}
