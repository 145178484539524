import { PrivateGateway } from '@/core/gateways';
import { sortBy } from 'lodash';
import { Database } from '@/core/types';

export async function getAvailableDatabases() {
  const response = await PrivateGateway.getAvailableDatabases();

  if (response.ok) {
    const data = await response.json();
    return sortBy<Database>(
      data.databases.filter((db: Database) => db.description),
      ['description']
    );
  } else {
    throw new Error('Could not get database users.');
  }
}
