import { post, get } from '../http';

const BASE_URL = process.env.NEXT_PUBLIC_FRONT_END_BASE_URL;

const Routes = {
  currentUser: '/api/auth/current-user',
  login: '/api/auth/login'
};

export const getCurrentUser = async () => {
  const url = BASE_URL + Routes.currentUser;
  return get({ url });
};

export const login = async ({ token }: { token: string | string[] }) => {
  const url = BASE_URL + Routes.login;
  return post({
    url,
    body: JSON.stringify({ token })
  });
};
