import { UppyFile } from '@uppy/core';
import { lookup } from 'mime-types';

import { Method } from '../types';
import { FileGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { Environment, recordCreation } from '../utils';

export const s3PluginOptions = {
  async getUploadParameters(file: UppyFile<any>) {
    const contentType = lookup(<string>file.meta.extension) || 'application/octet-stream';
    const response = await handleResponse(
      FileGateway.create({
        folder_id: <string>file.meta.folderId,
        name: file.name,
        extension: file.meta.extension,
        content_type: contentType
      })
    );

    if (Environment.isTest || Environment.isLocal) {
      await recordCreation(file, response.url);
    }

    return {
      method: Method.PUT,
      url: response.url,
      headers: {
        'Content-Disposition': `attachment; filename=${file.name}.${file.meta.extension}`,
        'Content-Type': contentType
      }
    };
  }
};
